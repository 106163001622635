import React, { useEffect, useRef } from 'react';
import './index.css'

function App() {
    let noTexts: string[];
    noTexts = ['No', 'Wait a minute', 'Are you serious', 'Please no', 'Come on', 'Not that button',
        'Why are you still trying'];
    let currentNoText = useRef(1);

    useEffect(() => {
        const noButton = document.querySelector('.no') as HTMLButtonElement;
        noButton.addEventListener('click', () => {
            noButton.textContent = noTexts[currentNoText.current++];
            if (currentNoText.current >= noTexts.length) {
                currentNoText.current = 0;
            }
        });

        const yesButton = document.querySelector('.yes') as HTMLButtonElement;
        yesButton.addEventListener('click', () => {
            const title = document.querySelector('h1') as HTMLDivElement;
            title.textContent = 'Happy valentine\'s day';
            const buttons = document.querySelector('.buttons') as HTMLDivElement;
            buttons.style.visibility = 'hidden';
        });
    }, [noTexts]);

    return (
        <div className="container">
            <div className="purpose">
                <h1>Would you be my valentine</h1>
                <img className="gif" src={require('./assets/purpose.gif')} alt="purpose" />
                <div className="buttons">
                    <button className="yes">Yes</button>
                    <button className="no">No</button>
                </div>
            </div>
        </div>
    )
}

export default App;